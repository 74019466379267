
import { mapMutations } from 'vuex';
import Link from '@/components/ui/link.vue';

export default {
  name: 'Cta',

  components: { Link },

  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    ...mapMutations(['toggleContactForm', 'closeContactForm']),
  },
};
