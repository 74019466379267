
import Cta from '@/components/ui/cta.vue';
import WpImage from '~/components/ui/img.vue';

export default {
  name: 'Hero',

  components: {
    Cta,
    WpImage,
  },

  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    headingArray() {
      return this.data.heading.split(' ');
    },
  },
};
